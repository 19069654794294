import { useUserRegionCH } from '@cdc-internal/mkt-nav-footer'
import { navigate } from 'gatsby'
import React, { useEffect } from 'react'

import TemplateController from '../category-page-template/template-controller'
import Research from './research-view'

const ResearchController = ({ data, location }) => {
  const userRegion = useUserRegionCH()
  const viewProps = TemplateController({ data, location, slug: 'research' })
  useEffect(() => {
    if (userRegion === 'SG') {
      navigate(`${process.env.GATSBY_NAVBAR_BASE_DOMAIN}/sg/research`)
    }
  }, [userRegion])

  return <Research {...viewProps} />
}

export default ResearchController
