import { graphql } from 'gatsby'
import React from 'react'

import ReportController from '../screens/research'

const Research = (props) => <ReportController {...props} />

export default Research

export const pageQuery = graphql`
  query ($lang: String) {
    articles: allWpReportsAndanalysis(
      sort: { fields: dateGmt, order: DESC }
      filter: { locale: { locale: { eq: $lang } } }
    ) {
      edges {
        node {
          id
          title
          slug
          nodeType
          content
          excerpt
          uri
          date(formatString: "MMM DD, YYYY")
          dateGmt
          categories {
            nodes {
              name
              slug
              description
              id
            }
          }
          acf: acfReportAndAnalysis {
            keywords
            readingDuration
          }
          featuredImage {
            node {
              id
              altText
              sourceUrl

            }
          }
        }
      }
    }
  }
`
