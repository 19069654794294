import './research-styles.scss'

import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  AdsSection,
  CardGroupHeader,
  CardList,
  FilterMobile,
  Filters,
  Seo,
  Title,
} from '../../components'

const ResearchView = ({
  articles,
  trendingArticles,
  handleFilter,
  selectedCategories,
}) => {
  const { t, i18n } = useTranslation()
  return (
    <div>
      <Seo
        title={t('research.meta.title')}
        description={t('research.meta.description')}
        link="http://crypto.com/research"
        lang={i18n.language}
      />
      <FilterMobile
        type="research"
        title="topics"
        onClick={handleFilter}
        selectedCategories={selectedCategories}
      />
      <div className="report-container has-mobile-filter">
        <CardGroupHeader
          title="research"
          data={trendingArticles}
          titleUrl="/research"
        />
        <div className="report-box">
          <Title title={t('research.titleList')} />
          <Filters
            type="research"
            title="topics"
            onClick={handleFilter}
            selectedCategories={selectedCategories}
          />
          <CardList data={articles} />
        </div>

        <AdsSection />
      </div>
    </div>
  )
}

export default ResearchView
